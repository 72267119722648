import React from "react"
import videoBG from '../video/anim.mp4'
import './Main.css'
import Biomedical from '../video/biosq.png'
import Vision from '../video/vision.jpg';
import Wafer from '../video/wafer.jpg';

const Main = () => {
    return (
        <div className="main">
            <video src={videoBG}  type="video/mp4" autoPlay loop muted />
            <div className='landing'>
                <div className="landingTop">
                    <h2>Wavefront Analysis Systems </h2>
                    <h3>W.A.S</h3>
                </div>
                <div className='landingCenter'>
                    <h1>
                        TOTAGRAPHY
                    </h1>
                    <h3>A NEW KIND OF VISION</h3>

                </div>
            </div>
            <div className='midContent'>
                <div className='content'>
                    <div className='centerLeftContent centerContentChild'>
                        <div className="centerContentTextArea">
                            <h1>Understanding Totagraphy</h1>
                            <ul>
                                <li>
                                Totagraphy and sight have a lot in common. Sight is considered the richest most complex sense out of our five senses.  Sight gives us two essential abilities.  
                                The first ability gives us a sense of depth (the third dimension or 3D).  The second ability makes it possible for us to classify/analyze (characterize or describe the qualities of) what we are seeing. 
                                 These two abilities combined make the world understandable through our eyes.
                                The Totagraphic process together with machine learning gives the computer the ability to see intelligently.
                                </li>
                                <li>
                                Totagraphy is a new methodology for 3D information retrieval.
                                Using our special masking process as well as a specialized iterative optimization process,
                                 we have new ways of designing and implementing imaging systems such as digital microscope, telescope or camera to 
                                 capture a scene and reconstruct it in full 3D. This is done through our specialized 
                                 phase and amplitude retrieval method. As we are able to retrieve full 3D information on an object, we 
                                are able to classify and analyze the object efficiently.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='content'>
                    <div className='centerRightContent centerContentChild'>
                        <div className="centerContentTextArea">
                            <h1>Totagraphy and Machine Learning</h1>
                            <ul>
                                <li>
                                    Totagraphy and machine learning allows us to recover information that is lost or very expensive to recover when
                                    using current sensing technologies. Recovering this information by using totagraphy and machine learning allows us to
                                    gain new knowledge about the target object at a very fine level of detail. 
                                    This technology can be used in an extraordinary number of fields such as manufacturing quality assurance, 
                                    autonomous driving, and the biomedical field allowing for many possibilities and use cases.
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>


                <div className='content'>
                    <div className='centerLeftContent centerContentChild'>
                        <div className="centerContentTextArea">
                            <h1>Ease of Implementation</h1>
                            <ul>
                                <li>
                                    With so much power in the masking and optimization processes, totagraphy allows
                                    for easy switching of design and implementation from one imaging system to another.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='content'>
                    <div className='centerRightContent centerContentChild'>
                        <div className="centerContentTextArea">
                            <h1>High Precision Accuracy</h1>
                            <ul>
                                <li>
                                    Extreme measurement accuracy allows for very precise
                                    industry requirements.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='content'>
                    <div className='centerLeftContent centerContentChild'>
                        <div className="centerContentTextArea">
                            <h1>Simplified Hardware and Software</h1>
                            <ul>
                                <li>
                                    Recording only intensity and separate recovery of phase information by our
                                    totagraphic process leads to minimized hardware cost, simplified hardware/software systems,
                                    and thereby increased speed of computation
                                </li>
                                <li>
                                    Totagraphy allows for quick iteration and classification over many objects ensuring it can keep up with
                                    demanding tasks
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='content'>
                    <div className='centerRightContent centerContentChild'>
                        <div className="centerContentTextArea">
                            <h1>Who We Are</h1>
                            <ul>
                                <li>
                                    A research group lead by Dr. Okan Ersoy at Purdue University is bringing totagraphy to the world.
                                </li>
                                <li>
                                    Graciously sponsored by Kirk Gerchberg.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='largeContent'>
                    <div className='content1'>
                        <div className="bottomFlexContent">
                            <div className="contentImageContainer">
                                <img src={Wafer} alt="Wafer to show quality control"/>
                            </div>
                            <div className="contentText">
                                <h3>Wafer Quality Control</h3>
                                <p>Using Totagraphy, we can get extremely precise measurements 
                                    allowing for very accurate quality control</p>
                            </div>
                        </div>
                        <div className="bottomFlexContent">
                            <div className="contentImageContainer">
                                <img src={Biomedical} alt="Biomedical example for demostration purposes"/>
                            </div>
                            <div className="contentText">
                                <h3>Biomedical Usage</h3>
                                <p>Totagraphic masking and optimization allows for smaller equipment 
                                    to process complex medical tasks in an easy to use and 
                                    portable manner
                                </p>
                            </div>
                        </div>
                        <div className="bottomFlexContent">
                            <div className="contentImageContainer">
                                <img src={Vision} alt="Indianapolis 500 autonomous driving contestant"/>
                            </div>
                            <div className="contentText">
                                <h3>Computer Vision</h3>
                                <p>Very accurate and precise 3D measurements are very useful in computer vision tasks,
                                    such as autonomous driving applications
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main;